<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Advanced Search
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12" md="10" offset-md="1">
                    <v-text-field v-model="search.name" color="secondary" label="Theme name includes:"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" offset-md="1">
                    <v-text-field v-model="search.minHeroes" color="secondary" label="Minimum # theme heroes:"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" offset-md="2">
                    <v-text-field v-model="search.maxHeroes" color="secondary" label="Maximim # theme heroes:"></v-text-field>
                </v-col>
                <v-col cols="12" md="10" offset-md="1">
                    <v-autocomplete v-model="search.hero" :items="getHeroes" color="secondary" label="Include this hero:"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="text-center">
                    <v-btn color="red" outlined @click="clearSearch()">
                        <v-icon left>fas fa-times</v-icon>
                        Clear Search
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                    <v-btn color="secondary" outlined @click="searchThemes()">
                        <v-icon left>fas fa-search</v-icon>
                        Search
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-toolbar flat dense dark color="secondary" v-if="searchList.length > 0">
            <v-toolbar-title>Search Results:</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4" v-for="(theme, i) in searchList" :key="i" class="entries" @click="openPage(theme)" style="cursor: pointer">
                    <div class="subtitle-1">{{theme.name}}</div>
                    <div class="caption">{{theme.description}}</div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return{
            search: {
                hero        : "",
                maxHeroes   : "",
                minHeroes   : "",
                name        : ""
            },
            searchList: []
        }
    },
    methods: {
        clearSearch(){
            this.search.heroList    = []
            this.search.maxHeroes   = ""
            this.search.minHeroes   = ""
            this.search.name        = ""
            this.searchList         = []
        },
        openPage(selected){
            window.open(`/theme/${selected.id}`)
        },
        searchThemes(){
            let filterByName    = this.search.name      ? this.getThemes.filter(theme => theme.name.toLowerCase().includes(this.search.name.toLowerCase())) : this.getThemes
            let filterByMinNum  = this.search.minHeroes ? filterByName.filter(theme => theme.heroes.length >= this.search.minHeroes)    : filterByName
            let filterByMaxNum  = this.search.maxHeroes ? filterByMinNum.filter(theme => theme.heroes.length <= this.search.maxHeroes)  : filterByMinNum
            let filterByHeroes  = this.search.hero      ? filterByMaxNum.filter(theme => theme.heroes.includes(this.search.hero))       : filterByMaxNum
            this.searchList     = filterByHeroes
        }
    },
    computed: {
        getHeroes(){
            return this.$store.getters.getHeroes.map(hero => hero.name).sort()
        },
        getThemes(){
            let approvedThemes = this.$store.getters.getThemes.filter(theme => theme.approved == "true")
            return approvedThemes.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()){
                    return 1
                }
                else {
                    return -1
                }
            })
        }
    }
}
</script>